import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from 'react-i18next';
import dubai from '../assest/bachkk.b847f9bbe2da61866cae.jpg';
import "swiper/css";
import "./slider.css";

export default function Slider() {
    const { t } = useTranslation();

    const scrollToWebsite = () => {
        // Replace this with the ID or class of your website section
        const websiteSection = document.getElementById('websiteSection');
        if (websiteSection) {
            websiteSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            <Swiper className="mySwiper">
                <SwiperSlide>
                    <img src={dubai} alt="Dubai Skyline" />
                    <div className="mytext">
                        <h6>{t('slider.title')}</h6>
                        <p>{t('slider.slogan')}</p>
                        {/* <button className="mybtn" onClick={scrollToWebsite}>{t('slider.button')}</button> */}
                    </div>
                </SwiperSlide>
                {/* Add more slides as needed */}
            </Swiper>
        </>
    );
}
