
import './App.css';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import Home from './Home';


function App() {
  return (
    <div className="App">
     <Routes>

   
<Route path="/" exact={true} element={<Home />} />
</Routes>
    </div>
  );
}

export default App;
