import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Snackbar from '@mui/material/Snackbar';
import './Form.css';
import image from '../assest/photo_2024-07-06_17-41-13-removebg-preview.png';

function ContactForm() {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [snackbar, setSnackbar] = useState({ open: false, message: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch('http://localhost:5000/api/v1/contactemail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
        .then(response => response.json())
        .then(data => {
            if (data.message === 'Email sent successfully') {
                setSnackbar({ open: true, message: t('contactForm.successMessage') });
            } else {
                setSnackbar({ open: true, message: t('contactForm.errorMessage') });
            }
        })
        .catch((error) => {
            setSnackbar({ open: true, message: t('contactForm.errorMessage') });
        });
    };

    const handleClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <div className='container'>
            <div className='form-image-container'>
                <img src={image} alt='Interior Design' className='background-image' />
                <form onSubmit={handleSubmit} className='contact-form'>
                    <h2>{t('contactForm.title')}</h2>
                    <div className='form-group'>
                        <input
                            type='text'
                            name='name'
                            placeholder={t('contactForm.name')}
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className='form-group'>
                        <input
                            type='email'
                            name='email'
                            placeholder={t('contactForm.email')}
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className='form-group'>
                        <textarea
                            name='message'
                            placeholder={t('contactForm.message')}
                            value={formData.message}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type='submit' className='submit-btn'>{t('contactForm.send')}</button>
                </form>
            </div>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={snackbar.message}
            />
        </div>
    );
}

export default ContactForm;
