import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'flag-icons/css/flag-icons.css';
import './btn.css'
const TranslateButton = () => {
  const { i18n } = useTranslation();
  const [textDirection, setTextDirection] = useState('ltr');

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("lng", language);
    setTextDirection(language === 'en' ? 'ltr' : 'rtl');
  };

  useEffect(() => {
    setTextDirection(i18n.language === 'en' ? 'ltr' : 'rtl');
  }, [i18n.language]);

  return (
    <div className='option' style={{ direction: textDirection }}>
      <button className="icon-btn" onClick={() => changeLanguage('en')}>
        <span className="fi fi-us" style={{ fontSize: '24px' }}></span> {/* USA flag for English */}
      </button>
      <button  onClick={() => changeLanguage('Ar')}>
        <span className="fi fi-ae" style={{ fontSize: '24px' }}></span> {/* UAE flag for Arabic */}
      </button>
    </div>
  );
};

export default TranslateButton;
