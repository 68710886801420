import React from 'react';
import './footer.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-content">
        <h4 className="company-name">{t('slogan.text')}</h4>
        <p className="slogan">{t('slogan.description')}</p>
        <div className="contact-info">
          <p>{t('footer.internationalCommunications')}: {t('footer.emails.communications')}</p>
          <p>{t('footer.businessManagement')}: {t('footer.emails.businessManager')}</p>
          <p>{t('footer.ceo')}: {t('footer.emails.ceoEmail')}</p>
          <p>{t('footer.support')}: {t('footer.emails.supportEmail')}</p>
        </div>
        <div className="social-icons">
          <a href="https://www.facebook.com" className="social-icon"><FacebookIcon /></a>
          {/* <a href="https://www.twitter.com" className="social-icon"><TwitterIcon /></a> */}
          <a href="https://www.instagram.com/hayatalmina.com" className="social-icon"><InstagramIcon /></a>
          <a href="https://www.linkedin.com/hayatalmina" className="social-icon"><LinkedInIcon /></a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
