import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import banner from '../../assest/dubai.webp'
const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: '4rem 2rem', display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', backgroundColor: '#800020' }}>
      <Box 
        component="img"
        sx={{ width: { xs: '100%', md: '50%' }, borderRadius: '8px', marginBottom: { xs: '2rem', md: '0' } }}
        src={banner}// Adjust the path as needed
        alt="About Us"
      />
      <Box sx={{ padding: '2rem', color: 'black', flex: 1 }}>
        <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#F5DEB3' }}>
          {t('aboutUsTitle')}
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: 'white' }}>
          {t('aboutUsContent')}
        </Typography>
        <Button variant="contained" sx={{ backgroundColor: 'black', color: '#F5DEB3', marginTop: '1rem' }}>
          {t('learnMore')}
        </Button>
      </Box>
    </Box>
  );
};

export default AboutUs;
