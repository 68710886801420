import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Import MUI icons
import NetworkIcon from '@mui/icons-material/SettingsEthernet';
import ExhibitionsIcon from '@mui/icons-material/Event';
import TechnologyIcon from '@mui/icons-material/Devices';
import OnlinePresenceIcon from '@mui/icons-material/Visibility';
import CollaborationIcon from '@mui/icons-material/People';

const OurServices = () => {
  const { t } = useTranslation();

  // Array of services with icons and translated text
  const services = [
    { icon: <NetworkIcon fontSize="large" sx={{ color: 'wheat' }} />, title: t('networkingTitle'), description: t('networkingDescription') },
    { icon: <ExhibitionsIcon fontSize="large" sx={{ color: 'wheat' }} />, title: t('exhibitionsTitle'), description: t('exhibitionsDescription') },
    { icon: <TechnologyIcon fontSize="large" sx={{ color: 'wheat' }} />, title: t('technologyTitle'), description: t('technologyDescription') },
    { icon: <OnlinePresenceIcon fontSize="large" sx={{ color: 'wheat' }} />, title: t('onlinePresenceTitle'), description: t('onlinePresenceDescription') },
    { icon: <CollaborationIcon fontSize="large" sx={{ color: 'wheat' }} />, title: t('collaborationTitle'), description: t('collaborationDescription') }
  ];

  return (
    <Box sx={{ padding: '4rem 2rem', textAlign: 'center' }}>
      <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#F5DEB3' }}>
        {t('servicesTitle')}
      </Typography>
      <Typography variant="subtitle1" gutterBottom sx={{ color: '#F5DEB3' }}>
        {t('servicesSubtitle')}
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                padding: '2rem',
                borderRadius: '8px'
              }}
            >
              {service.icon}
              <Typography variant="h6" component="h3" gutterBottom sx={{ color: '#F5DEB3' }}>
                {service.title}
              </Typography>
              <Typography variant="body2" sx={{ color: '#F5DEB3' }}>
                {service.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default OurServices;
