import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box } from '@mui/material';

import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import HomeIcon from '@mui/icons-material/Home';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import logo from '../assest/photo_2024-07-06_17-41-13-removebg-preview.png';
import TranslateButton from '../Translate/TranslateBtn';

const Nav = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const appBarStyle = {
    zIndex: 300,
    position: 'fixed',
    background: 'transparent',
    boxShadow: 'none',
    backdropFilter: 'blur(1px)',
  };

  const toolbarStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: isMobile ? '80px' : '100px',
  };

  const logoStyle = {
    color: 'wheat',
  };

  const iconContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    width: isMobile ? 140 : 160,
  };

  const iconButtonStyle = {
    '& .MuiSvgIcon-root': {
      fontSize: isMobile ? '20px' : '300px',
    },
  };

  const goToHomePage = () => {
    window.location.href = '/'; // Replace with the actual home page URL if different
  };

  const callFakeDubaiNumber = () => {
    const fakeDubaiNumber = '+971523043369';
    window.location.href = `tel:${fakeDubaiNumber}`;
  };

  return (
    <AppBar style={appBarStyle}>
      <Toolbar style={toolbarStyle}>
        <Typography variant="h6" component="div" style={logoStyle}>
          <img src={logo} alt="" width={100} />
        </Typography>
  
        <Box style={iconContainerStyle}>
         
          <IconButton style={iconButtonStyle} color="inherit" onClick={callFakeDubaiNumber}>
            <PhoneInTalkIcon />
          </IconButton>
          {/* <IconButton style={iconButtonStyle} color="inherit">
         <TranslateButton/>
          </IconButton> */}
          <TranslateButton/>
          </Box> 
      </Toolbar>
    </AppBar>
  );
};

export default Nav;
