import React from 'react'
import Slider from './Components/Slider'
import Nav from './Components/Nav'
import AboutUs from './Components/about/About'
import Footer from './Components/Footer/Footer'
import OurServices from './Components/Servisess/Servicess'
import ContactForm from './Components/Form'


function Home() {
  return (
    <div>
        <Nav/>
      <Slider/>
      <AboutUs/>
   <OurServices/>
   <ContactForm/>
      <Footer/>
    </div>
  )
}

export default Home
